<template>
  <div class="proposal-overview flex flex-col justify-between min-h-screen">
    <Header />
    <Content class="mb-auto">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span
            class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
            >Terms of Use</span
          >
        </h1>
      </div>
      <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <ol role="list">
          <li>
            Your Agreement
            <p class="mt-8 text-xl text-gray-500 leading-8">
              These General Terms of Use (GTU) applies to every user of this website provided by TumorSHOT s.r.o., Lannova 2113/2A, Nové Město, 110 00 Praha 1, Czech Republic, Id. No.: 09324925 (“TumorSHOT”),regardless of the place of access or residence.
            </p>
            
          </li>

          <li>
            Grant Process
            <p class="mt-8 text-xl text-gray-500 leading-8">
              Companies and individuals may apply for a grant on this website for projects, research and other activities which demonstrate potential for impact on cancer basic research, medicinal chemistry, molecular targeting or prevention.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              Applying for a grant via the website does not entitle the applicant to any rights, payments and/or compensation with which the applicant expressly agrees by submitting the registration form. 
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              Eligibility of an application, review of applications and selection of any applicant are under sole discretion of TumorSHOT. TumorSHOT reserves the right to select any number of applicants or not to select any applicant for funding. TumorSHOT reserves the right to terminate the grant program partially or as a whole at any time. This website is not a call for offers or an offer to enter into an agreement.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              TumorSHOT evaluates each application that meets eligibility criteria on its merits relative to other applications and available funding using a research proposal internal review system. The time to review each grant application may vary. TumorSHOT may contact the applicant for additional information at any time during the application review process. 
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              TumorSHOT may invite you to execute all necessary documents concerning conditions of provision of the grant, especially in terms of development of the applied-for-project and its sustainability, intellectual property and other rights arising thereof. You will be free to review and ultimately decide on whether to accept such conditions without any sanction, but payment of the grant or its part may be conditional upon accepting such documents.
            </p>
            
          </li>

          <li>
            Use of Website
            <p class="mt-8 text-xl text-gray-500 leading-8">
              All content on this website and any other website or system provided to you by TumorSHOT is the property of TumorSHOT and is protected by copyright and/or database rights. You undertake not to use, extract and/or re-utilize any part thereof without prior consent of TumorSHOT.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              TumorSHOT makes no representations about the suitability of the information contained in the documents and related graphics published on this site for any purpose. All such documents and related graphics are provided “as is” without warranty of any kind.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              TumorSHOT hereby disclaims all representations, warranties and conditions with regard to this information, including all implied warranties, fitness for a particular purpose, title and non-infringement. All access and use of and the content thereof is at the own risk of the user. In no event shall entities of TumorSHOT be liable for any damage or loss whether in an action of contract, negligence or other tortious action, arising out of or in connection with the use or performance of information available from this site.

            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              The documents and related graphics published on this website could include technical inaccuracies, typographical and other errors. Content may be amended and/or removed at any time without prior notice.

            </p>
            
          </li>

          <li>
            Governing law and jurisdiction
            <p class="mt-8 text-xl text-gray-500 leading-8">
              The Terms of Use and any legal relations (whether directly or indirectly) related to the Terms of Use, use of website, any potential claims shall be governed by Czech law and any disputes shall be subject to the jurisdiction of Czech courts.
            </p>
          </li>
        </ol>
      </div>
    </Content>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header"
import Content from "@/components/Content"
import Footer from "@/components/Footer"

export default {
  name: "TermsOfUse",
  components: {
    Header,
    Content,
    Footer,
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>
